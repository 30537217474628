import React from "react"
import Helmet from "react-helmet"
import GithubIcon from '../images/github.inline.svg';

export default ({ pageMeta, children }) => (
    <>
        <Helmet 
            htmlAttributes={{ lang: 'en' }}
            title={`BradSlocum.dev | ${pageMeta.title}`}
            meta={[
                {
                  name: `description`,
                  content: pageMeta.description,
                }
              ]}
        >
        </Helmet>
        <div class="py-6 px-6 md:px-16 lg:px-24 font-main">
            <header class="pb-2 mb-4 border-gray-700 border-b-4">
                <div class="grid grid-rows-2 grid-cols-2 gap-x-0 md:grid-rows-1 md:grid-cols-3">
                    <a class="text-2xl col-start-1 row-start-1" href="/">Brad Slocum</a>
                    <div class="text-2xl text-right col-start-2 row-start-1 md:col-start-3 ">    
                        <a class="mr-2 inline-flex items-center" href="https://github.com/bradslo" alt="Github">
                            <GithubIcon class="social-media-icon"/>
                        </a>
                    </div>
                    <nav class="text-lg col-span-2 col-start-1 row-start-2 md:row-start-1 md:col-start-2 md:mr-24 md:text-right">
                        <a class="mr-3" href="/">Home</a>
                        <a href="/now">What I'm Doing</a>
                    </nav>
                </div>
            </header>
            <main>
                {children}
            </main>
        </div>
    </>
)
